._{
    display: flex;
    position: fixed;
    width: 100%;
    height: 100%;
    background: #111;
    z-index: 10;
    align-items: center;
    justify-content: center;
    transition: all 2s ease;
}
.icon{
    height: 91px;
    animation: view 2s ease .3s infinite normal forwards;
}
@keyframes view {
    0% {
        opacity: 1;
    }
    50% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}