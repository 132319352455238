._{
    border-bottom: 1px solid #353535;
    border-top: 1px solid #353535;
    background: rgba(0, 0, 0, 0.50);
    backdrop-filter: blur(12.5px);
    display: flex;
    gap: 20px;
    flex-wrap: wrap;
    margin-top: 80px;
    padding: 20px 10px;
}
.block{
    display: flex;
    gap: 10px;
    align-items: center;
    width: calc(50% - 10px);
}
.text{
    color: #FFF;
    font-family: "PT Sans Narrow";
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 100%; /* 14px */
}
@media (max-width: 500px) {
    .text {
        font-size: 14px;
    }
}