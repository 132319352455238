._{
    margin-left: 336px;
    border-top: 1px solid #353535;
    background: rgba(0, 0, 0, 0.50);
    backdrop-filter: blur(12.5px);
    height: 383px;
    margin-top: 150px;
}
.block{
    max-width: 1170px;
    width: calc(100% - 80px);
    margin: 0 40px;
}
.block2_2{
    max-width: 1170px;
    display: flex;
    width: 100%;
    align-items: flex-end;
    justify-content: space-between;
    padding-top: 40px;
    margin: 0 40px;
}
.block1{
    height: 210px;
    display: flex;
    justify-content: center;
}
.block2{
    border-top: 1px solid #353535;
    display: flex;
    justify-content: center;
}
.text1{
    color: #AD8D39;
    font-family: "PT Sans Narrow";
    font-size: 10px;
    font-style: normal;
    font-weight: 400;
    line-height: 120%;
}
.logo{
    height: 90px;
}
.text2{
    color: #FFF;
    font-family: "PT Sans Narrow";
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: 120%;
    margin-top: 40px;
}
.form{
    margin-top: 30px;
    display: flex;
    gap: 30px;
    align-items: flex-start;
}
.text3{
    color: #FFF;
    font-family: "PT Sans Narrow";
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 120%;
    margin-top: 10px;
}
@media (max-width: 1729px) {
    ._{
        margin-left: 241px;
    }
}
@media (max-width: 1469px) {
    ._{
        margin-left: 91px;
    }
}
@media (max-width: 1063px) {
    .form{
        margin-top: 10px;
        display: flex;
        gap: 10px;
        align-items: center;
        flex-direction: column;
    }
    .text2{
        text-align: center;
        margin-top: 20px;
    }
    ._{
        height: 523px;
    }
    .block1 {
        height: 350px;
    }
}
@media (max-width: 824px) {
    ._ {
        margin-left: 0;
    }
}
@media (max-width: 599px) {
    .text2{
        margin-top: 7px;
    }
}
