._{
    display: flex;
    min-width: 55px;
    min-height: 55px;
    justify-content: center;
    align-items: center;
    border: 1px solid #FFF;
    background: #000;
    transition: all .3s ease;
    cursor: pointer;
}
._:hover{
    box-shadow: 7px 7px 0 0 #FFF;
}