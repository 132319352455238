._{
    display: flex;
    justify-content: center;
    padding-top: 100px;
    padding-left: 336px;
}
.block{
    max-width: 1170px;
    margin: 0 40px;
    display: flex;
    gap: 30px;
    align-items: center;
    justify-content: center;
}
.fon{
    width: 40%;
}
.text1{
    color: #FFF;
    font-family: "Playfair Display SC";
    font-size: 52px;
    font-style: normal;
    font-weight: 700;
    line-height: 120%;
}
.text2{
    width: 460px;
    color: #FFF;
    font-family: "PT Sans Narrow";
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 120%;
    margin-top: 40px;
}
.texts{
    width: 490px;
}
.text3{
    width: 460px;
    color: #FFF;
    font-family: "PT Sans Narrow";
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 120%;
    margin-top: 20px;
}
@media (max-width: 1729px) {
    ._{
        padding-left: 241px;
    }
}
@media (max-width: 1469px) {
    ._{
        padding-left: 91px;
    }
}
@media (max-width: 949px) {
    .text2 {
        width: 360px;
    }
    .text3 {
        width: 360px;
    }
}
@media (max-width: 824px) {
    ._ {
        padding-left: 0;
    }
}
@media (max-width: 699px) {
    .text1 {
        font-size: 48px;
    }
    .text2{
        margin-top: 20px;
    }
}
@media (max-width: 629px) {
    .block{
        flex-direction: column;
        gap: 0;
    }
    .fon{
        width: 60%;
        margin: 0 -100px -180px 0;
    }
}
@media (max-width: 559px) {
    .fon{
        width: 70%;
        margin: 0 -100px -100px 0;
    }
    .block{
        margin: 0;
    }
    .texts{
        width: calc(100% - 20px);
    }
    .text1 {
        font-size: 28px;
    }
    .text2,.text3{
        font-size: 16px;
        width: 100%;
    }
    .text2{
        margin-top: 40px;
    }
}
@media (max-width: 380px) {
    .fon{
        width: 80%;
        margin: 0 -40px -100px 0;
    }
}