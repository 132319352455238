._{
    display: flex;
    width: 370px;
    padding: 15px 20px;
    align-items: center;
    gap: 10px;
    color: rgba(17, 17, 17, 1);
    font-family: "PT Sans Narrow";
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 100.012%;
    border: none;
    border-radius: 0;
}