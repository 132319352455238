._{
    margin-top: 150px;
    position: relative;
}
.fon{
    height: 440px;
    border: 1px solid #353535;
    background: linear-gradient(90deg, rgba(242, 177, 51, 0.00) 73.65%, rgba(242, 177, 51, 0.20) 100%), linear-gradient(270deg, rgba(85, 14, 140, 0.00) 72.99%, rgba(85, 14, 140, 0.20) 100%), rgba(0, 0, 0, 0.50);
    backdrop-filter: blur(12.5px);
    z-index: -1;
    position: relative;
}
.fon2{
    position: absolute;
    height: 614px;
    z-index: -1;
    margin: -60px 0 0 -300px;
}
.fon3{
    position: absolute;
    height: 614px;
    right: 0;
    z-index: -1;
    margin: -85px 0 0 0;
}
.fon6{
    height: 180px;
}
.block{
    padding-left: 336px;
    display: flex;
    justify-content: center;
    top: -80px;
    position: absolute;
    width: calc(100% - 336px);
}
.block2{
    max-width: 1170px;
    width: 100%;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
}
.text1{
    color: #FFF;
    text-align: center;
    font-family: "Playfair Display SC";
    font-size: 52px;
    font-style: normal;
    font-weight: 700;
    line-height: 120%;
    margin-top: 10px;
}
.text2{
    color: #CAB16F;
    text-align: center;
    font-family: "PT Sans Narrow";
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: 120%;
    margin-top: 30px;
}
.form{
    margin-top: 50px;
    display: flex;
    gap: 30px;
}
.button{
    min-width: 270px;
}
@media (max-width: 1729px) {
    .block{
        width: calc(100% - 241px);
        padding-left: 241px;
    }
    .fon2{
        margin: -60px 0 0 -400px;
    }
}
@media (max-width: 1469px) {
    .block{
        width: calc(100% - 91px);
        padding-left: 91px;
    }
    .fon2{
        margin: -60px 0 0 -500px;
    }
}
@media (max-width: 1023px) {
    .form{
        width: 90%;
    }
    .fon2{
        margin: -60px 0 0 -700px;
    }
    .text1{
        font-size: 48px;
    }
}
@media (max-width: 899px) {
    .text1{
        font-size: 46px;
    }
}
@media (max-width: 824px) {
    .block{
        width: 100%;
        padding-left: 0px;
    }
    .text1{
        font-size: 42px;
    }
    .fon2{
        margin: -60px 0 0 -800px;
    }
}
@media (max-width: 749px) {
    .form{
        flex-direction: column;
        margin-top: 20px;
        gap: 10px;
        align-items: center;
    }
    .text1{
        margin: -10px 10px 0 10px;
        font-size: 32px;
    }
    .text2{
        margin-top: 10px;
    }
}