._{
    display: flex;
    justify-content: center;
    margin-top: 150px;
    margin-left: 336px;
}
.block{
    display: flex;
    gap: 30px;
    flex-direction: column;
}
.photos{
    display: flex;
    gap: 30px;
}
@media (max-width: 1729px) {
    ._{
        margin-left: 241px;
    }
}
@media (max-width: 1469px) {
    ._{
        margin-left: 91px;
    }
}
@media (max-width: 824px) {
    ._ {
        margin-left: 0;
    }
    .block{
        gap: 10px;
    }
    .photos{
        gap: 10px;
    }
}