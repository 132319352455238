._{
    width: 335px;
    height: 100vh;
    border-right: 1px solid #353535;
    position: fixed;
    z-index: 2;
}
.block1{
    height: 296px;
    border-bottom: 1px solid #353535;
    background: rgba(0, 0, 0, 0.50);
    backdrop-filter: blur(16.5px);
    display: flex;
    align-items: center;
    justify-content: center;
}
.logo{
    height: 91px;
}
.logo2{
    height: 52px;
}
.block2{
    background: #1D1D1D;
    height: calc(100vh - 297px - 50px);
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding-bottom: 50px;
}
.menuBlock{
    display: flex;
    flex-direction: column;
    gap: 40px;
    padding-top: 80px;
    padding-right: 36px;
}
.menuItem{
    color: #A9A9A9;
    text-align: right;
    font-family: "PT Sans Narrow";
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 100.012%; /* 18.002px */
    text-transform: uppercase;
    cursor: pointer;
    transition: all .3s ease;
}
.menuItem:hover{
    color: #FFF;
}
.menuItem:active{
    color: #D2B96B;
}
.block2_2{
    display: flex;
    flex-direction: column;
    align-items: center;
}
.icon{
    cursor: pointer;
}
.iconPath{
    opacity: 1;
    transition: all .3s ease;
}
.icon:hover > .iconPath {
    opacity: 0;
}
.iconsBlock{
    display: flex;
    gap: 20px;
    align-items: center;
    margin-bottom: 20px;
    width: calc(100% - 36px);
    margin-right: 36px;
    justify-content: flex-end;
}
.iconMenu{
    cursor: pointer;
    transition: all .3s ease;
    opacity: .55;
}
.iconMenu:hover{
    opacity: 1;
}
.menuHover{
    padding: 8px 15px;
    border: 1px solid #FFF;
    background: #000;
    box-shadow: 0px 0px 12px 0px rgba(0, 0, 0, 0.30);
    color: #FFF;
    font-family: "PT Sans Narrow";
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 100.012%;
    text-transform: uppercase;
    position: absolute;
    white-space: nowrap;
    left: 70px;
    transition: all .3s ease;
    opacity: 0;
    z-index: -1;
    user-select: none
}
.tel{
    color: #FFF;
    text-align: right;
    font-family: "PT Sans Narrow";
    font-size: 24px;
    font-style: normal;
    font-weight: 400;
    line-height: 100.012%; /* 24.003px */
    text-transform: uppercase;
    text-decoration: none;
    width: calc(100% - 36px);
    margin-right: 36px;
}
.tel2{
    color: #FFF;
    text-align: right;
    font-family: "PT Sans Narrow";
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: 100.012%;
    text-transform: uppercase;
    text-decoration: none;
    margin-top: 140px;
}
.email{
    text-decoration: none;
    color: #FFF;
    text-align: right;
    font-family: "PT Sans Narrow";
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: 100.012%;
    margin-top: 20px;
}
.email2{
    text-decoration: none;
    color: #FFF;
    text-align: right;
    font-family: "PT Sans Narrow";
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 100.012%;
}
@media (max-width: 1729px) {
    ._ {
        width: 240px;
    }
}
@media (max-width: 1469px) {
    ._ {
        width: 90px;
    }
    .block1 {
        height: 196px;
    }
    .block2 {
        height: calc(100vh - 196px - 50px);
    }
    .menuBlock{
        padding-right: 0;
        align-items: center;
        width: 100%;
    }
    .iconsBlock{
        margin-bottom: 40px;
        margin-right: 0;
        justify-content: flex-end;
        flex-direction: column;
    }
}
@media (max-width: 824px) {
    ._{
        width: 100%;
        height: 100px;
        display: flex;
    }
    .block1 {
        height: 100px;
        width: 100px;
        border-right: 1px solid #353535;
    }
    .block2 {
        height: 100px;
        width: calc(100% - 101px);
        padding: 0;
        border-bottom: 1px solid #353535;
    }
    .logo{
        height: 43px;
    }
    .block2_2{
        display: flex;
        flex-direction: row-reverse;
        align-items: center;
        padding: 0 12px;
        height: 100px;
        justify-content: space-between;
    }
    .iconsBlock{
        margin-bottom: 0;
        flex-direction: row;
        width: auto;
    }
    .textMenu{
        color: #FFF;
        text-align: right;
        font-family: "PT Sans Narrow";
        font-size: 18px;
        font-style: normal;
        font-weight: 400;
        line-height: 100.012%; /* 18.002px */
        text-transform: uppercase;
        cursor: pointer;
    }
    .menuSmall{
        display: flex;
        justify-content: flex-end;
        gap: 10px;
        margin-top: 26px;
    }
    .openMenu{
        transition: all .3s ease;
        cursor: pointer;
    }
    .openMenu2{
        margin-left: -27px;
        cursor: pointer;
    }
    .fon{
        position: fixed;
        background: linear-gradient(252deg, rgba(242, 177, 51, 0.00) 50%, rgba(242, 177, 51, 0.09) 100%), linear-gradient(62deg, rgba(85, 14, 140, 0.00) 32.42%, rgba(85, 14, 140, 0.15) 100%), rgba(0, 0, 0, 0.65);
        backdrop-filter: blur(6.5px);
        width: 100%;
        height: calc(100vh - 101px);
        left: 0;
        top: 101px;
    }
    .menu2{
        border-bottom: 1px solid #353535;
        background: #1D1D1D;
        height: auto;
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        justify-content: center;
        gap: 20px;
        padding: 40px 50px 30px 0;
    }
    .menuItem{
        color: #FFFFFF;
    }
}