._{
    display: flex;
    justify-content: center;
    margin-top: 100px;
    margin-left: 336px;
}
.block{
    width: 570px;
}
.text1{
    color: #FFF;
    font-family: "Playfair Display SC";
    font-size: 36px;
    font-style: normal;
    font-weight: 700;
    line-height: 110%;
    opacity: 0;
    transition: all 1s ease;
}
.text2{
    color: #FFF;
    text-edge: cap;
    font-family: "PT Sans Narrow";
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 120%;
    margin-top: 30px;
    opacity: 0;
    transition: all 1s ease;
}
.text3{
    color: #FFF;
    font-family: "PT Sans Narrow";
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 120%;
    margin-top: 50px;
    opacity: 0;
    transition: all 1s ease;
}
.view1{
    animation: view1 1s ease 0s 1 normal forwards;
}
.text2_1{
    position: relative;
}
.text2_1:after{
    color: rgba(0, 0, 0, .4);
    content: 'мечтал';
    position: absolute;
    left: 0px;
    z-index: -1;
    text-shadow: 7px 7px 0 #AD8D39, -28px 19px 38px rgba(0, 0, 0, 0.45);
    opacity: 0.4;
}
@keyframes view1 {
    0% {
        opacity: 0;
        transform: translateX(-20px);
    }

    100% {
        opacity: 1;
        transform: translateX(0);
    }
}
.view2{
    animation: view2 1s ease .5s 1 normal forwards;
}
@keyframes view2 {
    0% {
        opacity: 0;
        transform: translateX(-20px);
    }

    100% {
        opacity: 1;
        transform: translateX(0);
    }
}
.view3{
    animation: view3 1s ease 1s 1 normal forwards;
}
@keyframes view3 {
    0% {
        opacity: 0;
        transform: translateX(-20px);
    }

    100% {
        opacity: 1;
        transform: translateX(0);
    }
}
@media (max-width: 1729px) {
    ._{
        margin-left: 241px;
    }
}
@media (max-width: 1469px) {
    ._{
        margin-left: 91px;
    }
}
@media (max-width: 824px) {
    ._{
        margin-left: 0;
    }
    .block{
        width: 90%;
        max-width: 570px;
    }
    .text3{
        margin-top: 20px;
    }
}
@media (max-width: 499px) {
    .text1{
        font-size: 28px;
    }
    .text2{
        font-size: 18px;
    }
    .text3{
        font-size: 18px;
        margin-top: 40px;
    }
}