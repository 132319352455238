._{
    display: flex;
    justify-content: center;
    margin-top: 150px;
    margin-left: 336px;
}
.block{
    max-width: 1170px;
    width: 90%;
}
.text1{
    color: #FFF;
    font-family: "Playfair Display SC";
    font-size: 52px;
    font-style: normal;
    font-weight: 700;
    line-height: 120%;
    text-align: center;
}
.text2{
    color: #FFF;
    font-family: "Playfair Display SC";
    font-size: 36px;
    font-style: normal;
    font-weight: 700;
    line-height: 120%; /* 43.2px */
    text-transform: uppercase;
}
.text3{
    color: #FFF;
    font-family: "PT Sans Narrow";
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 120%; /* 21.6px */
}
.block2{
    display: flex;
    margin-top: 110px;
    gap: 30px;
}
.block2_1{
    display: flex;
    flex-direction: column;
    gap: 30px;
    width: calc(50% - 15px);
}
.text2_1{
    position: relative;
}
.text2_1:after{
    color: rgba(0, 0, 0, .4);
    content: 'клиент';
    position: absolute;
    left: 0px;
    z-index: -1;
    text-shadow: 7px 7px 0 #AD8D39, -28px 19px 38px rgba(0, 0, 0, 0.45);
    opacity: 0.4;
}
@media (max-width: 1729px) {
    ._{
        margin-left: 241px;
    }
}
@media (max-width: 1469px) {
    ._{
        margin-left: 91px;
    }
}
@media (max-width: 979px) {
    .text1{
        font-size: 45px;
    }
    .text2{
        font-size: 29px;
    }
    .block2{
        margin-top: 80px;
    }
}
@media (max-width: 824px) {
    ._ {
        margin-left: 0;
    }
    .text1{
        font-size: 36px;
    }
    .text2{
        font-size: 24px;
    }
    .text3{
        font-size: 16px;
    }
    .block2{
        margin-top: 50px;
        gap: 20px;
    }
}
@media (max-width: 619px) {
    .text2{
        font-size: 20px;
    }
    .text3{
        font-size: 15px;
    }
    .block2_1{
        gap: 30px;
        width: calc(50% - 5px);
    }
    .block{
        width: calc(100% - 20px);
    }
}
@media (max-width: 469px) {
    .text1{
        font-size: 28px;
    }
    .text2{
        font-size: 24px;
    }
    .text3{
        font-size: 16px;
    }
    .block2{
        flex-direction: column;
    }
    .block2_1{
        width: 100%;
        gap: 30px;
    }
    .block2{
        gap:40px;
    }
}