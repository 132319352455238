._{
    display: flex;
    justify-content: center;
    padding-top: 150px;
    padding-left: 336px;
}
.block{
    max-width: 1170px;
    width: 90%;
    display: flex;
}
.fonBlock{
    width: 60%;
    position: relative;
}
.fon{
    width: 100%;
}
.text1{
    color: #FFF;
    font-family: "Playfair Display SC";
    font-size: 52px;
    font-style: normal;
    font-weight: 700;
    line-height: 120%;
}
.text2{
    color: #FFF;
    font-family: "PT Sans Narrow";
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 120%;
    margin-top: 39px;
}
.form{
    margin: 40px 0 0 -70px;
    z-index: 1;
}
.button{
    margin-top: 20px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 15px;
}
.text3{
    color: #FFF;
    font-family: "PT Sans Narrow";
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 120%;
}
@media (max-width: 1729px) {
    ._{
        padding-left: 241px;
    }
}
@media (max-width: 1469px) {
    ._{
        padding-left: 91px;
    }
}
@media (max-width: 1269px) {
    .form {
        margin: 40px 0 0 -10%;
    }
}
@media (max-width: 1023px) {
    .form {
        margin: 40px 0 0 -30%;
    }
    .fonBlock {
        width: 80%;
    }
}
@media (max-width: 824px) {
    ._{
        padding-left: 0;
        padding-top: 100px;
    }
    .text1{
        font-size: 48px;
    }
    .text2{
        margin-top: 20px;
    }
}
@media (max-width: 699px) {
    ._{
        padding-left: 0;
        position: relative;
    }
    .form {
        margin: 40px 0 0 -70%;
    }
    .fonBlock {
        width: 90%;
    }
}
@media (max-width: 639px) {
    .text1{
        font-size: 28px;
    }
    .text2{
        font-size: 16px;
    }
}
@media (max-width: 459px) {
    .block {
        width: calc(100% - 20px);
    }
    .form {
        margin: 0;
        width: 100vw;
    }
    .fonBlock{
        width: 100%;
        height: 630px;
        position: absolute;
        top: 0;
        left: 0;
        overflow: hidden;
    }
    .fon{
        height: 100%;
        width: auto;
    }
    .fon2{
        background: linear-gradient(225deg, rgba(85, 14, 140, 0.00) 50%, rgba(85, 14, 140, 0.20) 100%), linear-gradient(0deg, rgba(0, 0, 0, 0.70) 0%, rgba(0, 0, 0, 0.70) 100%);
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        opacity: .8;
    }
}