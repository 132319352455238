._{
    max-width: 370px;
    max-height: 370px;
    cursor: pointer;
    transition: all .3s ease;
    overflow:hidden;
    position: relative;
    opacity: 1;
    width: calc(28vw - 30px);
    height: calc(28vw - 30px);
}
._:hover{
    box-shadow: 7px 7px 0 0 #FFF;
}
._:hover > .photo{
    filter: grayscale(0);
}
.photo{
    position: absolute;
    top:50%;
    left:50%;
    transform:translate(-50%,-50%);
    max-width:370px;
    max-height:370px;
    width: calc(28vw - 30px);
    height: calc(28vw - 30px);
    object-fit: cover;
    object-position: top;
    transition: all .3s ease;
    filter: grayscale(1);
    opacity: 0;
}
.open{
    position: fixed;
    top: 20px;
    height: calc(100vh - 40px);
    z-index: -1;
    transition: all .3s ease;
    display: flex;
    left: 20px;
    width: calc(100% - 40px);
    justify-content: center;
    gap: 30px;
    filter: none;
    opacity: 0;
    padding: 20px 20px 20px 20px;
}
.openPhoto{
    max-width: 100%;
    height: auto;
    max-height: 100%;
    transition: all .3s ease;
    z-index: 2;
}
.fon{
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    background: linear-gradient(252deg, rgba(242, 177, 51, 0.00) 70.05%, rgba(242, 177, 51, 0.09) 100%), linear-gradient(62deg, rgba(85, 14, 140, 0.00) 68.02%, rgba(85, 14, 140, 0.15) 100%), rgba(0, 0, 0, 0.65);
    backdrop-filter: blur(9px);
    z-index: 2;
    transition: opacity .3s ease;
}
.photoBlock{
    height: 100%;
    display: flex;
    align-items: center;
}
.closeBlock{

}
.view{
    animation: view 1s ease 0s 1 normal forwards;
}
@keyframes view {
    0% {
        opacity: 0;
        //transform: translateY(50px);
    }

    100% {
        opacity: 1;
        //transform: translateY(0);
    }
}

@media (max-width: 824px) {
    ._ {
        width: calc(33vw - 15px);
        height: calc(33vw - 15px);
    }
    .photo {
        width: calc(33vw - 15px);
        height: calc(33vw - 15px);
    }
    .photoBlock{
        height: auto;
    }
    .open{
    }
}