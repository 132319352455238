._{
    background-image: url("../../image/fon1.png");
    width: 100%;
    height: 1000px;
    background-size: 100%;
    background-position-x: center;
    background-repeat: no-repeat;
}
.block{
    width: calc(100% - 336px);
    margin-left: 336px;
}
.header{
    border-bottom: 1px solid #353535;
    background: rgba(0, 0, 0, 0.50);
    backdrop-filter: blur(12.5px);
    height: 152px;
    display: flex;
    gap: 30px;
    flex-direction: column;
    justify-content: center;
    padding-left: 40px;
}
.headerBlock{
    display: flex;
    gap: 30px;
}
.headerText{
    color: #FFF;
    font-family: "PT Sans Narrow";
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 100%;
    width: 250px;
    display: flex;
    align-items: center;
    gap: 10px;
}
.text1{
    color: #FFF;
    font-family: "Playfair Display SC";
    font-size: 52px;
    font-style: normal;
    font-weight: 700;
    line-height: 110%; /* 57.2px */
}
.block2Text{
    margin: 225px 20px 0 40px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 50px;
}
.block2Images{
    position: absolute;
    top: 220px;
    right: 10%;
    width: 471px;
    padding: 71px 0 90px 100px;
    overflow: hidden;
}
.block2ImageBlock{
    overflow: hidden;
    transform: rotate(45deg);
    width: 232px;
    height: 233px;
    box-shadow: -28px 19px 38px 0px rgba(0, 0, 0, 0.45);
}
.photo1{
    box-shadow: -28px 19px 38px 0px rgba(0, 0, 0, 0.45);
    transform: rotate(-45deg) scale(1.1);
    left: -15%;
    top: -14.4%;
    position: absolute;
    z-index: 2;
}
.block2ImageBloc2{
    overflow: hidden;
    transform: rotate(45deg);
    width: 165px;
    height: 166px;
    box-shadow: -28px 19px 38px 0px rgba(0, 0, 0, 0.45);
}
.photo2{
    box-shadow: -28px 19px 38px 0px rgba(0, 0, 0, 0.45);
    transform: rotate(-45deg) scale(0.79);
    left: -41%;
    top: -42%;
    position: absolute;
}
.block2ImageFon{
    margin: -48px 0px 0 -42px;
    position: absolute;
    z-index: 0;
}
.block2ImageFon2{
    margin: -34px 0px 0 -29px;
    position: absolute;
    z-index: 0;
}
.block2Images2{
    margin: -109px 0 0 263px;
}
.block2ImageBloc3{
    overflow: hidden;
    transform: rotate(45deg);
    width: 194.14px;
    height: 194.14px;
    box-shadow: -28px 19px 38px 0px rgba(0, 0, 0, 0.45);
}
.photo3{
    box-shadow: -28px 19px 38px 0px rgba(0, 0, 0, 0.45);
    transform: rotate(-45deg) scale(0.91);
    left: -27%;
    top: -27%;
    position: absolute;
}
.block2ImageFon3{
    margin: -40.35px 0 0 -35px;
    position: absolute;
    z-index: 0;
}
.block2Images3{
    margin: -24px 0 0 111px;
}
.block3{
    display: flex;
    gap: 30px;
    margin-left: 40px;
    margin-top: 150px;
}
.block3Text{
    color: #FFF;
    font-family: "PT Sans Narrow";
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 120%;
    width: 259px;
    display: flex;
    gap: 10px;
}
.block3Line{
    width: 1px;
    min-width: 1px;
    margin-top: 3px;
    height: 91%;
    background: #FFFFFF;
}
@media (max-width: 1729px) {
    .block{
        width: calc(100% - 241px);
        margin-left: 241px;
    }
    ._ {
        background-size: 1900px;
    }
}
@media (max-width: 1469px) {
    .block{
        width: calc(100% - 91px);
        margin-left: 91px;
    }
}
@media (max-width: 1279px) {
    .block2Images{
        right: 10px;
    }
}
@media (max-width: 1159px) {
    .block2Text {
        margin: 20px 20px 0 40px;
    }
    .blockImage{
        transform: scale(0.6);
    }
    .blockImage2{
        display: flex;
        margin-left: 20px;
    }
}
@media (max-width: 824px) {
    .block{
        width: 100%;
        margin-left: 0;
        padding-top: 101px;
    }
    .block3{
        margin-top: 50px;
    }
    .blockImage2{
        margin-top: 20px;
    }
}
@media (max-width: 719px) {
    .headerText{
        font-size: 14px;
        width: calc(50% - 20px);
    }
    .headerBlock{
        flex-wrap: wrap;
        gap: 20px;
    }
    .header{
        gap: 20px;
        height: auto;
        padding: 20px 10px;
    }
    .blockImage{
        transform: scale(0.5);
        width: 33%;
    }
    .blockImage2{
        margin-left: -10px;
        margin-top: 0;
    }
    .text1{
        font-size: 36px;
    }
    .block2Text{
        margin: 0 10px 0 10px;
        gap: 30px;
    }
    .block3{
        gap: 18px;
        margin-left: 10px;
        margin-top: 50px;
        flex-direction: column;
    }
    .block3Line{
        height: auto;
        margin-top: -1px;
    }
    .block3Text {
        width: 265px;
    }
}
@media (max-width: 569px) {
    .blockImage{
        transform: scale(0.4);
        width: 31%;
    }
    .block2Text{
        margin: -20px 10px 0 10px;
    }
}
@media (max-width: 459px) {
    .blockImage{
        transform: scale(0.35);
        width: 31%;
        margin-top: -25px;
    }
    .block2Text{
        margin: -25px 10px 0 10px;
    }
}
@media (max-width: 399px) {
    .blockImage{
        transform: scale(0.3);
        width: 31%;
    }
    .block2Text{
        margin: -40px 10px 0 10px;
    }
}
