._{
    display: flex;
    justify-content: center;
    padding-top: 120px;
    padding-left: 336px;
}
.block{
    max-width: 1170px;
    width: 90%;
}
.text1{
    color: #FFF;
    font-family: "Playfair Display SC";
    font-size: 52px;
    font-style: normal;
    font-weight: 700;
    line-height: 120%;
    text-align: center;
}
.text2{
    position: relative;
}
.text2:after{
    color: rgba(0, 0, 0, .4);
    content: 'мы';
    position: absolute;
    left: 1px;
    z-index: -1;
    text-shadow: 7px 7px 0 #AD8D39, -28px 19px 38px rgba(0, 0, 0, 0.45);
    opacity: 0.4;
}
.text3{
    color: #FFF;
    font-family: "PT Sans Narrow";
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 120%;
}
.text4{
    color: #BEA47D;
    font-family: "PT Sans Narrow";
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 120%;
    margin-top: 15px;
}
.textBlock{
    display: flex;
    gap: 15px;
    align-items: center;
    width: 270px;
    align-items: flex-start;
}
.texts{
    display: flex;
    margin-top: 110px;
    gap: 30px;
    flex-wrap: wrap;
    justify-content: center;
}
.texts2{
    display: flex;
    margin-top: 44px;
    gap: 30px;
}
.numberBlock{
    width: 24px;
    min-width: 24px;
    padding-top: 12px;
}
@media (max-width: 1729px) {
    ._{
        padding-left: 241px;
    }
}
@media (max-width: 1469px) {
    ._{
        padding-left: 91px;
    }
}
@media (max-width: 1219px) {
    .texts2{
        margin-top: 24px;
    }
}
@media (max-width: 824px) {
    ._{
        padding-left: 0;
    }
    .text1{
        font-size: 48px;
    }
}
@media (max-width: 633px) {
    .block{
        width: calc(100% - 20px);
    }
}
@media (max-width: 599px) {

    .text1{
        font-size: 28px;
    }
    .texts{
        margin-top: 30px;
        gap: 10px;
    }
    .texts2{
        gap: 10px;
        margin-top: 20px;
    }
    .textBlock{
        width: calc(50% - 5px);
    }
}
@media (max-width: 559px) {
    .texts{
        gap: 20px;
    }
    .textBlock{
        width: calc(100% - 5px);
        gap: 7px;
        flex-direction: column;
    }
    .numberBlock{
        padding-top: 0;
    }
    .text3{
        font-size: 14px;
        width: 60%;
    }
    .text4{
        margin-top: 5px;
    }
}