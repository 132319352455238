._{
    z-index: 10;
    position: fixed;
    display: flex;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background: linear-gradient(252deg, rgba(242, 177, 51, 0.00) 50%, rgba(242, 177, 51, 0.09) 100%), linear-gradient(62deg, rgba(85, 14, 140, 0.00) 32.42%, rgba(85, 14, 140, 0.15) 100%), rgba(0, 0, 0, 0.65);
    backdrop-filter: blur(17.5px);
    align-items: center;
    justify-content: center;
}
.block{
    display: inline-flex;
    flex-direction: column;
    align-items: flex-end;
    gap: 30px;
    margin: 10px;
}
.text1{
    color: #FFF;
    font-family: "Playfair Display SC";
    font-size: 52px;
    font-style: normal;
    font-weight: 700;
    line-height: 120%; /* 62.4px */
}
.text2{
    color: #FFF;
    font-family: "PT Sans Narrow";
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 120%; /* 21.6px */
    margin-top: 39px;
}
.form{
    margin-top: 30px;
    flex-direction: column;
    display: flex;
    gap: 10px;
}
.button{
    display: flex;
    margin-top: 20px;
}
.text3{
    margin-top: 15px;
    color: #FFF;
    font-family: "PT Sans Narrow";
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 120%; /* 14.4px */
}
.popup{
}
@media (max-width: 599px) {
    .text1{
        font-size: 32px;
    }
    .text2{
        margin-top: 19px;
    }
}