._{
    padding: 19px 66px 19px 66px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    border: 1px solid #FFF;
    background: #000;
    color: #FFF;
    text-align: center;
    font-family: "PT Sans Narrow";
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: 100.012%; /* 18.002px */
    text-transform: uppercase;
    transition: all .3s ease;
    cursor: pointer;
    white-space: nowrap;
    max-width: available;
    display: flex;
}
._:hover{
    box-shadow: 7px 7px 0 0 #FFF;
}